<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Concepto</h1>
      <p>
        Nuestro proyecto <em>Granja Caimito</em> pretende crear alimentos para el ser humano de una forma sostenible que
        imite un ecosistema natural. Algunos llaman a este enfoque <em>agricultura regenerativa</em>.</p>
      <p>
        No se trata de extraer del ecosistema, sino de generar abundancia de modo que podamos llevarnos el excedente
        para consumo humano sin forzar el declive de todo el sistema.
      </p>

      <h2 class="underdashed text-xl">Creación de una granja de ecosistemas</h2>
      <p>
        Nuestras tierras son 45 ha situadas en el Valle de los Pedroches en Andalucía, España. Está situada en un
        antiguo sistema agroforestal llamado Dehesa. El paisaje está dominado por robles y no queda mucho del resto de
        la vegetación que existió en su día. El suelo está en su mayor parte dañado y tiene muy poca materia orgánica.
        La siguiente foto es del verano de 2022:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-06-08_21-17-36.jpg">
        <img src="../../assets/images/2022-06-08_21-17-36.jpg">
      </picture>

      <p>
        Un ecosistema es complejo y no hay un camino claro para conseguir nada. La naturaleza es aditiva y
        para todo hay múltiples caminos y múltiples actores. La naturaleza es resistente porque se puede eliminar
        un elemento y el ecosistema sigue funcionando. Sin embargo, si se elimina demasiado es probable que se produzca
        su declive.
      </p>
      <p>
        Para nosotros eso significa que trabajamos simultáneamente en varios temas y tomamos decisiones basadas en el
        conocimiento pero en gran medida basadas en la observación. Lo que funciona en otros lugares nos sirve de
        inspiración, pero que no debemos copiar, sino encontrar nuestra manera de aplicar un principio que ha funcionado
        en una situación similar a la nuestra.
      </p>

      <h2 class="underdashed text-xl">Agricultura</h2>
      <p>
        Como el suelo está agotado y <router-link :to="{ name: 'desertification' }">
          desertificación</router-link> se ve claramente que no hay forma de plantar o recoger un cultivo en este
        momento. Eso ya se hizo en el pasado y condujo a la situación actual. Preferimos utilizar los recursos
        existentes, incluida el agua para restaurar el suelo y el bosque, de modo que más adelante podamos hacer
        agroforestería y obtener un producto.
      </p>

      <h2 class="underdashed text-xl">Impacto animal</h2>
      <p>
        Mantenemos un mayor número de animales, ya que desempeñan una función vital en nuestro esfuerzo de restauración.
        Por ejemplo, sus excrementos no sólo aportan nutrientes al suelo, sino que sus pezuñas abren la corteza para que
        se infiltre el agua. Y el desarraigo de los cerdos también ayuda al suelo. Dedicamos mucho tiempo a gestionar
        adecuadamente el impacto de los animales.
      </p>

      <YouTubeLink />
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import YouTubeLink from '@/components/YouTubeLink.vue'

export default {
  name: "ConceptES",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
